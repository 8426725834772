import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the AjaxProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

@Injectable({
  providedIn: 'root'
})
export class AjaxService {

  apiKey = "AIzaSyC0ftkTR0SHyIUVdXrL0ivxooj4VfcdyME";
  constructor(public http: HttpClient) {
  }

  isExisted(videoID) {
    const url = 'https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics,contentDetails&id='
      + videoID +
      '&key='
      + this.apiKey;
    return this.http.get(url);
  }
}
